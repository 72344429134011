 .source-container {

   border: 1px solid #e9ecef;
   display: flex;
   flex: 1;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   padding: 0.5em 0;
   box-shadow: 0 1px 1px 0 rgba(10, 22, 70, .1), 0 0 1px 0 rgba(10, 22, 70, .06);
   border-radius: 4px;
   cursor: pointer;
   margin-top: 0.5rem;

   img {
     height: 4em;
     width: 4em;
   }

   .content-container {
     flex: 2;
     flex-direction: column;
     display: flex;
     justify-content: center;

     .title {
       font-size: 18px;
       font-weight: 600;
     }

     .content {
       font-size: 16px;
     }
   }

   .icon-container {
     flex: 1;
     flex-direction: row;
     display: flex;
     align-items: center;
     justify-content: center;
   }
 }


@import "open-color/open-color.scss";
@import "../css/variables.module";

.imago {
  .App-toolbar {
    &.zen-mode {
      .ToolIcon__keybinding,
      .HintViewer {
        display: none;
      }
    }

    &__divider {
      width: 1px;
      height: 1.5rem;
      align-self: center;
      background-color: var(--default-border-color);
      margin: 0 0.5rem;
    }

    .stroke-width-pop{
      position: relative;
      width: 4.5rem;
      height: 10rem;
      input[type=range]{
        position: absolute;
        top:8.5rem;
        left:1.5rem;
        transform:rotate(-90deg);
        transform-origin:left bottom;
        width: 9rem;
      }   

      .panelColumn{
        position: absolute;
        top:0.8rem;
        left: 2rem;
        .buttonList{
          row-gap: 0.9rem;
          label{
            border:0;
            // svg{
            //   width: 1.5rem;
            //   height:1.5rem;
            // }
          }
        }
      }
    }

    .toolbar-button-contanier{
      position: relative;
    }
    .toolbar-pop-contanier{
      // display: flex;          
      // flex-direction:column;
      padding: 4px;
      display: grid;
      grid-template-columns: repeat(4, auto);
      grid-gap: 4px;
      border-radius: 4px;
    }
  }
  .toolbar-selected{
    box-shadow: 0 0 3px 1px #cccccc;
  }
}

@import "../css/variables.module";

.insert-embed {
  padding: 10px;

  .title {
    font-weight: bold;
    height: 2.5rem;
  }

  .field {
    display: flex;
    align-items: center;
    font-weight: bold;
    height: 2.5rem;
    gap: 10px;
  }

  .error {
    color: red;
    line-height: 1.5rem;
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;

    svg {
      width: 32px;
      height: 32px;
      margin-bottom: 18px;
    }
  }

  .actions {
    display: flex;
    justify-content: end;
    gap: 10px;
    height: 2rem;
    align-items: center;

    button {
      border: 1px solid #d8d8d8;
      background-color: #ffffff;
      height: 1.5rem;
      border-radius: 3px;
    }
  }

  .ant-card .ant-card-meta-title {
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 16px;
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;
  }
}

.set-background{
    display: grid;
    grid-template-columns: repeat(4,auto);
    gap: 20px;
    button{
        width: 24px;
        height: 24px;
        border: none;
        border-radius: 50%;
        overflow: hidden;
    }
    .color-point{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        // padding: 3px;
        border: 1px solid #ddd;
        // &-white{
        //     background: #DEDBD8;
        // }
    }

    .selected-color button {
        border: 1px solid #007bff;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
        transform: scale(1.1);
    }

}
.custom-color{
    display: flex;
    margin-top: 20px;
    border-top: 1px solid #ccc;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
}
.template-modal{
    padding: 10px;
    display: flex;
    grid-template-columns: minmax(150px,260px) auto;
    height: 606px;
    align-content:start;


    .search{
        display: flex;
        align-items: center;
        justify-self: center;
        height: 32px;
        border-bottom: 2px solid #000000;
        max-width: 400px;
        width: 100%;
        svg{
            fill: #000000;
        }
        input{                     
            height: 30px;
            width: 100%;
            border: 0;
            padding: 0;
            ::placeholder{
                color: #A0A0A0;
            }
            &:focus,
            &:focus-visible{
                outline: none ;
                box-shadow:none;
            }
            
        }
    }
    .menu{
        height: calc(100% - 100px);
        overflow: auto;
        ul,li{list-style: none; padding-left: 0;}
        li{
            cursor: pointer;
        }
        .active{
            background:#005FAC;
            color: white;
            border-radius: 1rem;
            display: inline-block;
        }
    }
    .content{
        display: flex;
        flex-direction: column;
        h2{
            width: 100%;
        }

        .content-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 35px;

        .category-list{
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
            li{
                list-style: none; 
                padding-left: 0;
                cursor: pointer;
                margin-bottom: 10px;
            }
            li.active{
                font-weight: bold;
                color: #005FAC;
            }
            li:hover {
                color: #035fa1;
              }
        }
        .template-list{
            width: 100%;
        }
        .card-cover{
            height: 123px;
            overflow: hidden;
            img{
                width: 100%;
            }
        }
        }
    }
}
.app-modal-user-modal {

    .Modal__content {
        background: #ffffff;
        border: 0;
    }

    .user-modal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: calc(15px / var(--device-pixel-ratio));
        padding: calc(5px / var(--device-pixel-ratio)) 0 calc(40px / var(--device-pixel-ratio)) 0;
        background: url("../assets/frontend/hexagon-yellow.svg") no-repeat top 26% left 2%/10%,
            url("../assets/frontend/triangle-rust.svg") no-repeat left 6% bottom 10%/10%,
            url("../assets/frontend/arrow-rigth-left.svg") no-repeat right 10% top 10%/10%,
            url("../assets/frontend/square-yellow.svg") no-repeat left 22% bottom 1%/10%,
            url("../assets/frontend/arrow-bottom-top.svg") no-repeat right 10% bottom 10%/20%;
    }

    .user-modal-header {
        padding: 0 calc(13px / var(--device-pixel-ratio));
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .user-modal-logo {
            img {
                width: 100%;
                max-width: calc(126px / var(--device-pixel-ratio));
            }
        }

        .user-modal-close {
            img {
                width: 100%;
                max-width: calc(24px / var(--device-pixel-ratio));
            }
        }
    }

    .user-modal-title {
        font-size: calc(1.5rem / var(--device-pixel-ratio));
        font-weight: bolder;
        text-align: center;
    }

    .user-modal-input {
        margin: 0 auto;
        position: relative;
        display: flex;
        flex-direction: column;
        // max-width: calc(435px / var(--device-pixel-ratio));
        width: 75%;

        .show-password {
            position: absolute;
            right: calc(45px / var(--device-pixel-ratio));
            top: calc(30px / var(--device-pixel-ratio));
            cursor: pointer;

            svg {
                width: calc(20px / var(--device-pixel-ratio));
                height: calc(20px / var(--device-pixel-ratio));
            }
        }
    }

    .user-modal-input img {
        position: absolute;
        left: calc(45px / var(--device-pixel-ratio));
        top: calc(16px / var(--device-pixel-ratio));
    }

    .user-modal-input input {
        height: calc(42px / var(--device-pixel-ratio));
        border: calc(1px / var(--device-pixel-ratio)) solid;
        border-radius: calc(5px / var(--device-pixel-ratio));
        text-align: center;
        margin: 0 calc(30px / var(--device-pixel-ratio));
        font-size: calc(15px / var(--device-pixel-ratio));
    }

    .user-modal-input input::placeholder {
        text-align: center;
    }

    .user-modal-input label {
        max-width: calc(370px / var(--device-pixel-ratio));
        margin: 0 calc(30px / var(--device-pixel-ratio));
        padding-bottom: calc(5px / var(--device-pixel-ratio));
        font-size: calc(15px / var(--device-pixel-ratio));
    }

    .user-modal-input span {
        color: red;
        max-width: calc(370px / var(--device-pixel-ratio));
        margin: 0 calc(30px / var(--device-pixel-ratio));
    }

    .user-modal-action {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        max-width: calc(435px / var(--device-pixel-ratio));
        width: 100%;

        .convenience-action {
            display: flex;
            justify-content: space-between;
            margin: 0 calc(30px / var(--device-pixel-ratio));
            padding: calc(10px / var(--device-pixel-ratio)) 0;

            input {
                height: calc(16px / var(--device-pixel-ratio));
            }

            label {
                font-size: calc(15px / var(--device-pixel-ratio));
            }

            a {
                cursor: pointer;
                font-size: calc(15px / var(--device-pixel-ratio));
            }
        }
    }

    .user-modal-action button {
        // background:linear-gradient(to bottom,#C26BAA,#893F95,#5D3391);
        background: #F66344;

        height: calc(42px / var(--device-pixel-ratio));
        line-height: calc(42px / var(--device-pixel-ratio));
        border: 0;
        border-radius: calc(10px / var(--device-pixel-ratio));
        color: #FFFFFF;
        text-align: center;
        margin: 0 calc(30px / var(--device-pixel-ratio)) 0 calc(30px / var(--device-pixel-ratio));
        max-width: calc(435px / var(--device-pixel-ratio));
        font-size: calc(15px / var(--device-pixel-ratio));
    }

    .user-modal-action button.third-legged-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        height: calc(45px / var(--device-pixel-ratio));
        border: calc(1px / var(--device-pixel-ratio)) solid #D8DADC;
        border-radius: calc(5px / var(--device-pixel-ratio));
        font-size: calc(19px / var(--device-pixel-ratio));
        margin: 0 calc(30px / var(--device-pixel-ratio));
        color: #000;
        gap: calc(10px / var(--device-pixel-ratio));
        width: calc(170px / var(--device-pixel-ratio));
        align-self: center;

        svg {
            width: calc(24px / var(--device-pixel-ratio));
            height: calc(24px / var(--device-pixel-ratio));
        }
    }

    .user-modal-create-account {
        font-size: calc(29px / var(--device-pixel-ratio));
        font-weight: bolder;
        color: #D0D2D3;
        text-align: center;
    }

    .divider {
        border-bottom: calc(1px / var(--device-pixel-ratio)) solid #D0D2D3;
        max-width: calc(370px / var(--device-pixel-ratio));
        width: 100%;
        position: relative;
        margin: 0 auto;
        margin-top: calc(15px / var(--device-pixel-ratio));
        margin-bottom: calc(15px / var(--device-pixel-ratio));
    }

    .divider-text {
        position: absolute;
        top: calc(-10px / var(--device-pixel-ratio));
        left: calc(100% / 3);
        background-color: white;
        padding: 0 calc(20px / var(--device-pixel-ratio));
        color: #D0D2D3;
        font-size: calc(12px / var(--device-pixel-ratio));
    }

    .footer-info {
        text-align: center;
        font-size: calc(15px / var(--device-pixel-ratio));
    }

    .bottom-white-space {
        height: calc(100px / var(--device-pixel-ratio));
    }

    @media only screen and (max-width:calc(768px / var(--device-pixel-ratio))) {
        .user-modal-action {
            .convenience-action {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                align-items: center;
                gap: calc(10px / var(--device-pixel-ratio));
            }
        }
    }

    .user-qr-code {
        display: flex;
        justify-content: center;
        justify-items: center;

        .qrcode-box {
            border: calc(1px / var(--device-pixel-ratio)) solid #ccc;
            padding-top: calc(5px / var(--device-pixel-ratio));
            padding-left: calc(5px / var(--device-pixel-ratio));
            padding-right: calc(5px / var(--device-pixel-ratio));
            border-radius: calc(5px / var(--device-pixel-ratio));
            box-sizing: border-box;

            canvas {
                height: calc(80px / var(--device-pixel-ratio));
                width: calc(80px / var(--device-pixel-ratio));
            }
        }
    }
}
.imago {
  .popover {
    position: absolute;
    z-index: 9;
    padding: 5px 0 5px;
    .Island{
      padding: 4px;
    }
  }
}

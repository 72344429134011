.ai-chat {
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 100%;
    overflow: hidden;

    p {
        margin: 0;
        padding: 0;
    }

    .header {
        height: 40px;
        padding: 2px 0;
        text-align: center;
        margin-top: 15px;
        display: flex;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 15px;
        justify-content: center;
        align-items: center;
        margin: 15px 15px 0 15px;

        svg {
            width: 20px;
            height: 20px;
        }

        .left-tools {
            margin-left: 5px;
            border-right: 1px solid #ccc;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            .upload-image {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }


        }



        input {
            margin-left: 5px;
            flex: 1;
            height: 90%;
            padding: 0 5px;
            background: #fff;
            transition: background-color 0.3s ease;
            border: none;
            outline: none;
            padding-inline: 0;
            padding-block: 0;
            padding-left: 2px;
            box-shadow: none;
        }

        .right-send-msg {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            cursor: pointer;
        }

    }

    .body {
        height: 100%;
        flex-grow: 1;
        overflow: auto;

        .item-actions {
            display: flex;
            gap: 0.75rem;

            svg {
                width: 1rem;
            }
        }
    }

    .ant-list-item-meta {
        h4 {
            padding: 0;
            margin: 0
        }
    }

    .ant-list-item-action {
        margin: 0 !important;

        svg {
            width: 1rem;
        }
    }


}
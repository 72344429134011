@import "../css/variables.module";

.imago {
  .collab-button {
    @include outlineButtonStyles;
    width: var(--lg-button-size);
    height: var(--lg-button-size);

    svg {
      width: var(--lg-icon-size);
      height: var(--lg-icon-size);
    }
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: white;
    flex-shrink: 0;

    &:hover {
      background-color: var(--color-primary-darker);
      border-color: var(--color-primary-darker);
    }

    &:active {
      background-color: var(--color-primary-darker);
    }

    &.active {
      background-color: #0fb884;
      border-color: #0fb884;

      svg {
        color: #fff;
      }

      &:hover,
      &:active {
        background-color: #0fb884;
        border-color: #0fb884;
      }
    }
  }

  &.theme--dark {
    .collab-button {
      color: var(--color-gray-90);
    }
  }

  .CollabButton.is-collaborating {
    background-color: var(--button-special-active-bg-color);

    .ToolIcon__icon svg,
    .ToolIcon__label {
      color: var(--icon-green-fill-color);
    }
  }

  .CollabButton-collaborators {
    :root[dir="ltr"] & {
      right: -5px;
    }
    :root[dir="rtl"] & {
      left: -5px;
    }
    min-width: 1em;
    min-height: 1em;
    line-height: 1;
    position: absolute;
    bottom: -5px;
    padding: 3px;
    border-radius: 50%;
    background-color: $oc-green-2;
    color: $oc-green-9;
    font-size: 0.6rem;
    font-family: "Cascadia";
  }
}

@import "../css/variables.module";

.imago {
  .Dialog {
    user-select: text;
    cursor: auto;
  }

  .Dialog__title {
    margin: 0;
    text-align: left;
    font-size: 1.25rem;
    border-bottom: 1px solid var(--dialog-border-color);
    padding: 0 0 0.75rem;
    margin-bottom: 1.5rem;
  }
}

.change-password{
    display:grid;
     grid-template-rows: 50px 70px 70px 50px 50px;
     h2{
        text-align: center;
        padding: 0;
        margin: 0;
     }
    .change-password-field{
        margin: 0 auto;
        position: relative;
        display: flex;
        flex-direction: column;
        max-width: 435px;
        width: 100%;
        input{
            height: 2rem;
            border: 1px solid;
            border-radius: 5px;
            text-align: center;        
            margin: 0 30px;
        }
    
        input::placeholder {
            text-align: center; 
        }
        span{
            color: red;
            max-width: 370px;
            margin: 0 30px;
            font-size: 0.75rem;
        }
       
    }

    .change-password-action{
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        max-width: 435px;
        width: 100%;
        button{
            background-color: #05A2CC;
            padding: 10px 30px;
            border: 0;
            border-radius: 5px;
            color: #FFFFFF;
            margin: 0 auto 0 auto;
            //width: 200px;
        }
    }
    .change-password-err{
        color: red;
        padding: 0 30px;
    }

}
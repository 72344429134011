.side-container {
  width: 30%;
  position: absolute;
  z-index: 10;
  right: 0;
  top: 0;
  display: flex;
  height: calc(100% - 2px);
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1200px) {
    width: 40%;
  }

  @media (max-width: 997px) {
    width: 25em;
  }

  @media (max-width: 400px) {
    width: 96%;
  }

  .side-box {
    width: 100%;
    border: 1px solid #e9ecef;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    margin-right: 5px;

    .side-box-header {
      height: auto;
      display: flex;
      flex-direction: column;

      .header-title {
        display: flex;
        justify-content: space-between;
      }

      .tab-header {
        display: flex;
        padding: 0;
        flex-direction: row;
        justify-content: space-between;
        // height: 3em;
        padding-top: 12px;
        // border: 1px solid red;

        .tab-list {
          border-bottom: 1px solid #e9ecef;
          // border-bottom: 1px solid red;
          // height: 100%;
          // margin: 10px 0 0 0;
          // padding: 0 10px 0 5px;
          flex: 1;
          list-style: none;
          display: flex;

          .tab {
            border: 1px solid transparent;
            // border: 1px solid red;
            border-bottom: none;
            bottom: -1px;
            cursor: pointer;

            display: flex;
            justify-content: center;
            align-items: center;
            list-style: none;
            // padding: 6px 12px;
            margin: 0 0.125em;
            height: 36px;
            width: 36px;
            position: relative;
          }

          .tab--selected {
            background: #fff;
            border-color: #e9ecef;
            border-radius: 5px 5px 0 0;
            color: #000;
          }
        }

        .tab-header-left {
          display: flex;
          border-bottom: 1px solid #e9ecef;
          // margin: 10px 0 0 0;
          // padding-bottom: 10px;
          list-style: none;
          justify-content: flex-start;
          width: 36px;
          padding-left: 10px;

          .browBtn {
            cursor: pointer;
          }

          .browBtn-disabled {
            width: 20px;
            height: 20px;
            padding: 5px 0px 0px;
            opacity: 0.2;
          }
        }

        .tab-header-right {
          display: flex;
          border-bottom: 1px solid #e9ecef;
          width: 36px;
          height: 36px;
          padding-top: 1px;
          cursor: pointer;
          list-style: none;
          justify-content: center;
          align-items: center;

          .tab-header-right-close {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .side-box-body {
      display: flex;
      flex-direction: column;
      // overflow-y: auto;
      // border: 1px solid red;
      padding: 0.75em 0.75em 0.2rem 0.75em;
      margin: 0;
      height: 100%;
      overflow: hidden;
    }

    .side-box-footer {
      display: flex;
      height: calc(1rem - 4px);
      border-top: 1px solid #e9ecef;
      padding: 0 0.75em;
      justify-content: center;
      align-items: center;

      background-color: #f0f0f0;

      .progress-bar {
        width: 100%;
        height: calc(100% - 2px);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        // background-color: #f0f0f0;
        // border-radius: 10px;
        overflow: hidden;
      }

      .range-input {
        width: calc(100% - 2px);
      }

      .progress-bar-fill {
        height: 0.25em;
        background-color: #57c7ff;
        transition: width 0.3s ease-in-out;
      }
    }
  }
}

.side-action {
  height: 2.75em;
  display: flex;
  justify-content: center;
  align-items: center;

  .action-button {
    height: 2.25em;
    display: flex;
    padding: 0 1em;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    background-color: #007BFF;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    text-decoration: none !important;
    font-weight: bold;
    font-size: 1em;
  }

  .action-button-select-lib {
    height: 2.25em;
    display: flex;
    padding: 0 1em;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    background-color: #000000;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    text-decoration: none !important;
    font-weight: bold;
    font-size: 1em;
  }

  .action-button-left {
    border-radius: 5px 0 0 5px;
  }

  .action-button-right {
    border-left: 1px solid #fff;
    border-radius: 0 5px 5px 0;
    padding: 0 0.5em;
    overflow: visible;

    svg {
      fill: #fff;
    }

  }

  .action-button-disable {
    background-color: #D8D8D8;
    opacity: 0.6;
    cursor: not-allowed;
  }

  .action-button-danger {
    background-color: #DB6965;
  }

  .action-button-default {
    background-color: #666;
    width: 60px;
  }

  .action-button-blue {
    background-color: #DB6965;
    width: 60px;
  }
}

.gray {
  color: #D8D8D8;
}

.side-content-action {
  margin-top: 0.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .content-action-button {
    height: 1.5rem;
    display: flex;
    padding: 0 1rem;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #6965db;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    text-decoration: none !important;
    font-weight: bold;
    font-size: 0.75em;
  }

  .content-action-button-disable {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .content-action-button[aria-disabled="true"] {
    background-color: #D8D8D8;
  }
}

.side-content-title {
  font-size: 1.25rem;
  font-weight: bold;
  height: 1.5rem;
  display: flex;
  justify-content: space-between;
}

.side-content-body {
  margin-top: 0.5rem;
  line-height: 1.25rem;
}

.margin75 {
  margin-top: 0.75rem;
}
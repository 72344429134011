@import "../css/variables.module";

.board_preview {
    text-align: center;
    height: 100%;
    width: 100%;
}

.board_preview canvas {
    width: 260px;
    height: 100px;
    // max-width: 100%;
    // max-height: 100%;
    // min-height: 100%;
}


.board_preview_full {
    text-align: center;
    height: 100%;
    width: 100%;
}

.board_preview_full canvas {
    width: 260px;
    height: 100px;
    // max-width: 100%;
    // max-height: 100%;
    // min-height: 100%;
}
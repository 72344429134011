.rich-main {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  box-sizing: border-box;

  // .w-e-scroll {
  //   overflow-y: hidden !important;
  // }
}
.features-modal {


    display: flex;
    flex-direction: column;
    padding: 10px !important;
    user-select: text;

    .features-modal-close {
        display: flex;
        justify-content: end;
    }

    a {
        cursor: pointer;

        svg {
            width: 20px;
            color: #000;

        }
    }

    .features-modal-menu {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .items {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            flex-wrap: nowrap;
            overflow: auto;
            padding-bottom: 5px;

            .btnNormal {
                height: 2.25em;
                display: flex;
                padding: 0 1em;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                background-color: #ffffff;
                text-align: center;
                white-space: nowrap;
                text-decoration: none !important;
                font-size: 14px;
                color: #000;
                border: 1px solid #1C1C1C;

            }

            .btn-disabled {
                height: 2.25em;
                display: flex;
                padding: 0 1em;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                background-color: #fff;
                color: #000;
                text-align: center;
                white-space: nowrap;
                text-decoration: none !important;
                font-size: 14px;
                opacity: 0.3;
                cursor: not-allowed;
                border: 1px solid #1C1C1C;
            }

            .btnActive {
                height: 2.25em;
                display: flex;
                padding: 0 1em;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                background-color: #1C1C1C;
                color: #ffffff;
                text-align: center;
                white-space: nowrap;
                text-decoration: none !important;
                font-size: 14px;
                color: #ffffff;
                border: 1px solid #1C1C1C;
            }
        }
    }

    .features-modal-content {
        height: 100%;
        padding: 30px 20px 20px 20px;
        overflow: auto;
        display: flex;
        flex-direction: column;

        .ant-radio-group-outline {
            border-radius: 20px;
            box-shadow: rgb(204, 204, 204) 3px 3px 3px;

            .ant-radio-button-wrapper {
                border-radius: 20px;
                border: 0;
                color: #ccc;
                padding-left: 25px;
                padding-right: 25px;
            }

            .ant-radio-button-wrapper-checked {
                background: #FF5E00;
                color: #fff;
            }

            .ant-radio-button-wrapper:hover {
                color: #000;
            }

            .ant-radio-button-wrapper:not(:first-child)::before {
                background: none;
            }
        }

        .template-modal {
            grid-template-columns: auto;
        }

        .action-switch {
            display: flex;
            justify-content: center;
        }

        .action-content {
            padding: 20px 0;
            display: flex;
            flex-direction: column;
            gap: 20px;
            flex-grow: 1;
        }

        .button-category {
            border: none;
            background-color: transparent;
        }

    }


}
.school-content {
    display: flex;
    min-height: 560px;
    flex-direction: column;
    align-items: center;

    h2 {
        width: 100%;
        text-align: center;
    }

    .list {

        .loading {
            border: 4px solid #f3f3f3;
            border-radius: 50%;
            border-top: 4px solid #3498db;
            width: 20px;
            height: 20px;
            -webkit-animation: spin 2s linear infinite;
            /* Safari */
            animation: spin 2s linear infinite;
        }

        /* Safari */
        @-webkit-keyframes spin {
            0% {
                -webkit-transform: rotate(0deg);
            }

            100% {
                -webkit-transform: rotate(360deg);
            }
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        display: flex;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
    }

    .card-cover {
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: center;
        justify-content: center;
    }
}
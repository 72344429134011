.web-embeds {
  width: var(--lg-button-size);
  height: var(--lg-button-size);
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    cursor: pointer;
  }
  a:hover {
    cursor: pointer;
    text-decoration: none;
  }

  a.web-embeds-title {
    color: #2657b0;
    display: flex;
    align-items: center;
    svg{
      width: 24px;
      height: 24px;
    }
  }
  // a.web-embeds-title:hover{
  //     width: 40px;
  //     color: #fff;
  //     background: #2657B0;
  //     text-decoration: none;
  //     border-radius: 4px;
  // }

  .web-embeds-list {
    width: 45px;
    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.5rem;
      cursor: pointer;
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}

.user-plan-change{
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    
    .user-plan-change-action{
        display: flex;
        justify-content: center;
        padding: 30px 0;
        button{
            border: 0;
            padding: 5px 10px;
            border-radius: 5px;
            color: #fff;
            min-width: 80px;
        }
        button.gree{
            background-color: #34A853;
        }
        button.cancel{
            background-color: #D8D8D8;
        }
    }
    .user-plan-change-confirmed{
        h2{
            text-align: center;
        }
    }
}
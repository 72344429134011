.imago {

    .side-content-body{
        p{
            padding-left: 10px;
        }
        .copy-pin-code{
            cursor: pointer;
            padding-left: 10px;
            svg{
                width: 1rem;
                height: 1rem;
                cursor: pointer;
            }
        }
    }

    .screen-sharing-host{
        position: absolute;
        height: 90px;
        width: 200px;
        top: 0;
        left: 0;
        padding: 10px;
        z-index: 9999;
    }

   
}
.participants-suggestion {
  display: flex;
  flex-direction: column;

  background: #ffffff;
  color: #FFFFFF;
  border-radius: 16px;
  overflow: hidden;
  justify-content: space-between;
  box-shadow: 2px 2px 10px #7d7c7c;

  .participants-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    background: #4d4c4a;
    padding: 10px;
    height: 34px;

    h4 {
      padding: 0;
      margin: 0
    }

    a {
      display: block;
      cursor: pointer;
      width: 22px;
      height: 22px;

      svg {
        color: #FFFFFF;
      }
    }
  }

  .participants-content {
    overflow: auto;
    padding: 10px 5px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 5px;


    .autodraw-suggerstions {

      display: flex;
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;
      gap: 1%;

      .fingure-img {
        width: 24%;

        img {
          width: 90%;
          height: 90%;
        }

      }

    }

  }
}
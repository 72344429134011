.resize-top-left {
    position: absolute;
    cursor: nwse-resize;
    height: 20px;
    width: 20px;
    z-index: 103;
    left: 0;
    top: 0;
    touch-action: none;
  }
  
  .resize-top {
    position: absolute;
    cursor: ns-resize;
    height: 10px;
    width: 100%;
    z-index: 102;
    left: 0;
    top: 0;
    touch-action: none;
  }
  
  .resize-top-right {
    position: absolute;
    cursor: nesw-resize;
    height: 20px;
    width: 20px;
    z-index: 103;
    right: 0;
    top: 0;
    touch-action: none;
  }
  
  .resize-right {
    position: absolute;
    cursor: ew-resize;
    width: 10px;
    height: 100%;
    z-index: 102;
    right: 0;
    top: 0;
    touch-action: none;
  }
  
  .resize-bottom-left {
    position: absolute;
    cursor: nesw-resize;
    width: 20px;
    height: 20px;
    z-index: 103;
    left: 0;
    bottom: 0;
    touch-action: none;
  }
  
  .resize-bottom {
    position: absolute;
    cursor: ns-resize;
    width: 100%;
    height: 10px;
    z-index: 102;
    bottom: 0;
    left: 0;
    touch-action: none;
  }
  
  .resize-right-bottom {
    position: absolute;
    cursor: nwse-resize;
    width: 20px;
    height: 20px;
    z-index: 103;
    right: 0;
    bottom: 0;
    touch-action: none;
  }
  
  .resize-left {
    position: absolute;
    cursor: ew-resize;
    width: 10px;
    height: 100%;
    z-index: 102;
    top: 20;
    left:0;
    bottom: 20;
    touch-action: none;
  }
 
.sticky-note-content {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #fff;

    .translate-part {
        width: 240px;
        height: 100%;
    }

    flex: 1;

    .sticky-note {
        background: #ffffff;
        height: 310px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 5px 5px 5px 5px;


        [contenteditable] {
            width: 100%;
            height: 100%;

        }

        [contenteditable]:focus {
            border: 0;
            outline: 0;
        }

        .header {
            height: 30px;
            border-bottom: 1px solid #ccc;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: 10px;
            gap: 0.5rem;

            div {
                display: flex;
                gap: 10px;
                align-items: center;
            }

            div.title {
                width: 200px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            a {
                cursor: pointer;
            }
        }

        .content {
            display: flex;
            word-wrap: break-word;
            flex-direction: column;
            padding: 0.5rem 1.5rem;
            overflow: auto;
            height: 220px;

            h4,
            p {
                padding: 0;
                margin: 0;
            }

            p {
                word-break: break-all;
            }

        }

        .colorSelect {
            height: 25px;
            border: 1px solid #ccc;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            a {
                cursor: pointer;
                display: flex;
                height: 100%;
                width: 100%;
            }

            a:hover {
                text-decoration: none;
            }
        }

        .footer {
            height: 40px;
            display: flex;
            gap: 0.5rem;
            justify-content: center;
            align-items: center;
            border-top: 1px solid #ccc;

            a {
                display: flex;
                width: 30px;
                text-align: center;
                cursor: pointer;
                color: #000;
            }

            a:hover {
                text-decoration: none;
            }
        }
    }

}
.join-collab-confirm {
  width: calc(100% - 2px);
  height: 180px;
  display: flex;
  flex-direction: column;

  .close-win {
    display: flex;
    justify-content: right;
    padding: 5px;
    height: 34px;

    a {
      cursor: pointer;

      svg {
        color: #000000;
        width: 24px;
        height: 24px;
      }
    }


  }

  .win-content {
    flex: 1;

    font-size: 16px;
    display: flex;
    flex-direction: column;

    .info {

      .name {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
      }

      .desc {
        margin-top: 15px;
        text-align: center;
      }
    }


    .button-group {

      flex: 1;
      display: flex;
      justify-content: center;
      padding: 40px 30px 20px 30px;

      .button {
        cursor: pointer;
        border-radius: 5px;
        width: 100px;
        height: 35px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;

      }

      .button-reject {
        background-color: #F44336;
      }

      .button:hover {
        opacity: 0.6;
      }

    }



  }
}
@import "../css/variables.module";

.personal-board {
    background: #ffffff;

    display: flex;
    flex-direction: column;

    width: 600px;
    height: 400px;


    @media (max-width: 700px) {
        width: 90%;
        height: 50%;
        transform: translate(5%, 25%);
    }

    border: 1px solid #ccc;
    box-shadow: 2px 2px 10px #7d7c7c;
    position: absolute;
    transform: translate(calc(var(--win-width) / 2 * 1px - 300px), calc(var(--win-height) / 2 * 1px - 200px));
    z-index: 10;


    .header {
        height: 30px;
        border-bottom: 1px solid #ccc;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: move;

        div {
            display: flex;
            align-items: center;
        }

        div.title {
            width: 200px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding-left: 5px;
        }

        div.close {
            padding-right: 5px;

            a {
                cursor: pointer;

                svg {
                    width: 20px;
                    color: #000;

                }
            }
        }


    }

    .content {
        display: flex;
        flex-direction: column;
        flex: 1;


    }




}
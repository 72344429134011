.stem-simulation-content {
  display: flex;

  min-height: 560px;

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 240px;
    height: 100vh;
    // background-color: rgba(255, 255, 255, 0.8);
    transition: transform 0.3s ease;
    transform: translateX(-100%);
    z-index: 2;
    /* Ensure the sidebar is above the content */
    overflow: auto;
    overflow-x: hidden;
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .hamburger {
    position: fixed;
    top: 15px;
    left: 10px;
    color: #333;
    border: none;
    padding: 10px;
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 3;
    /* Ensure the button is clickable */
  }

  .sidebar-content {
    padding: 20px;
  }

  .hamburger:hover {
    background-color: #f0f0f0;
  }



  /* Container for all checkboxes */
  .checkbox-container {
    padding: 20px;
    overflow: auto;
    padding-top: 50px;
    margin-top: 10px;
  }

  .main-title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
    cursor: pointer;
    user-select: none;
    display: flex;
  }

  /* Styles for checkbox categories */
  .checkbox-category {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }

  /* Styles to make checkbox categories aligned with their titles */
  .checkbox-category label {
    margin-left: 10px;
  }


  .content {
    display: flex;
    justify-content: center;
    align-items: center;

    .loading {
      margin-top: calc(20px / var(--device-pixel-ratio));
      border: 4px solid #f3f3f3;
      border-radius: 50%;
      border-top: 4px solid #3498db;
      width: 20px;
      height: 20px;
      -webkit-animation: spin 2s linear infinite;
      /* Safari */
      animation: spin 2s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }


  .list {
    width: 100%;
    align-self: flex-end;
  }

  .card-cover {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }

  .list .ant-list-item {
    padding: 1px;
    /* Adds space around each card */
  }

  .main-content {
    flex: 1;
    transition: margin-left 0.3s ease;
    height: 100%;
    // justify-content: space-between;
  }

  .main-content.shifted {
    margin-left: 220px;
    /* Adjust to match the sidebar width */
  }


  .card-cover img {
    width: 100%;
    height: auto;
  }

  .simulation-card {
    margin-bottom: 24px;
    /* Adds vertical space between cards */
  }

  // .ant-list-item {
  //   padding: 0 12px; /* Adds horizontal padding between cards */
  // }

  .card-cover img {
    border-radius: 8px;
    /* Optional: Add rounded corners to the images */
  }

  .ant-list-items {
    display: flex;
    flex-wrap: wrap;
  }

}
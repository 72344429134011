.editor-container {
  height: 100%;
  width: 100%;
  margin: 0;
  // background-color: #fff;
  // border: 1px solid #e8e8e8;
  // padding: 10px 5px;
  // box-shadow: 0 2px 10px rgb(0 0 0 / 12%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  overflow: hidden;

  padding: 10px;


  .editor-container-title {
    border-bottom: 1px solid #e8e8e8;
    padding: 10px 0;

    textarea {
      font-size: 30px;
      font-weight: 500;
      border: 0;
      width: 100%;
      line-height: 1;
      outline: none;
      padding-inline: 0;
      padding-block: 0;
      padding-left: 2px;
      box-shadow: none;
    }

    .time {
      margin-top: 20px;
      margin-bottom: 5px;
      color: #ccc;
    }
  }

  .editor-container-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

  }

}
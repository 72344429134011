.container {

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    height: 35px;
    width: 100%;
    display: flex;
    flex-direction: row;


    .header-title {
      flex: 1;
      padding-left: 16px;
      font-weight: bold;
    }

    .header-close {
      width: 25px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

  }

  .content {
    flex: 1;
    width: 100%;
    overflow: scroll;


    .item-select {
      background: #ECE7E4;
    }

  }




}


.participants-language {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  color: #FFFFFF;
  border-radius: 16px;
  overflow: hidden;
  justify-content: space-between;
  box-shadow: 2px 2px 10px #7d7c7c;

  .participants-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    background: #4d4c4a;
    padding: 10px;
    height: 34px;

    h4 {
      padding: 0;
      margin: 0
    }

    a {
      display: block;
      cursor: pointer;
      width: 22px;
      height: 22px;

      svg {
        color: #FFFFFF;
      }
    }
  }

  .participants-content {
    overflow: auto;
    padding: 10px 5px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 5px;

    .item-select {
      background: #ece7e4;
    }


  }
}

.ant-list-sm .ant-list-item {
  padding: 5px 16px;
}
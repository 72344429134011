@import "../css/variables.module";

.imago {
  .picker-container {
    display: inline-block;
    box-sizing: border-box;
    margin-right: 0.25rem;
  }


  .buttonList button{border: 0 !important;
    .color-picker {
      border: 0 ;
      box-shadow: none;
      border-radius: 0;
    }
  }
  .ant-popover-inner-content{
    .color-picker {
      border: 0 ;
      box-shadow: none;
      border-radius: 0;
    }
  }
  .picker-container button,
  .picker button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus-visible {
      outline: transparent;
      background-color: var(--button-gray-2);
      & svg {
        opacity: 1;
      }
    }

    &:hover {
      background-color: var(--button-gray-2);
    }

    &:active {
      background-color: var(--button-gray-3);
    }

    &:disabled {
      cursor: not-allowed;
    }

    svg {
      margin: 0;
      width: 36px;
      height: 18px;
      pointer-events: none;
      color: var(--text-primary-color) !important;
    }
  }

  .picker button {
    padding: 0.25rem 0.28rem 0.35rem 0.25rem;
  }

  .picker-triangle {
    width: 0;
    height: 0;
    position: relative;
    top: -5px;
    :root[dir="ltr"] & {
      left: 12px;
    }

    :root[dir="rtl"] & {
      right: 12px;
    }
    z-index: 10;

    &:before {
      content: "";
      position: absolute;
      border-style: solid;
      border-width: 0 9px 10px;
      border-color: transparent transparent transparentize($oc-black, 0.9);
      top: -1px;
    }

    &:after {
      content: "";
      position: absolute;
      border-style: solid;
      border-width: 0 9px 10px;
      border-color: transparent transparent var(--popup-bg-color);
    }
  }

  .picker-content {
    padding: 0.5rem;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 0.5rem;
    border-radius: 4px;
    :root[dir="rtl"] & {
      padding: 0.4rem;
    }
    
  }

  .picker-keybinding {
    position: absolute;
    bottom: 2px;
    font-size: 0.7em;
    color: var(--keybinding-color);

    :root[dir="ltr"] & {
      right: 2px;
    }

    :root[dir="rtl"] & {
      left: 2px;
    }
    @include isMobile {
      display: none;
    }
  }

  .picker-type-canvasBackground .picker-keybinding {
    color: #aaa;
  }

  .picker-type-elementBackground .picker-keybinding {
    color: $oc-white;
  }

  .picker-swatch[aria-label="transparent"] .picker-keybinding {
    color: #aaa;
  }

  .picker-type-elementStroke .picker-keybinding {
    color: #d4d4d4;
  }

  &.theme--dark {
    .picker-type-elementBackground .picker-keybinding {
      color: $oc-black;
    }
    .picker-swatch[aria-label="transparent"] .picker-keybinding {
      color: $oc-black;
    }
  }
  
}
.picker-content{padding: 0 !important;}
.vertical-range{
  display: grid;
  grid-template-columns: repeat(1, auto);
  height: 135px;
  width: 20px;
  :root[dir="rtl"] & {
    padding: 0.4rem;
  }
  position: relative;
  input{
    position: absolute;
    top: 115px;
    left:16px;
    transform:rotate(-90deg);
    transform-origin: left bottom;
    width: 135px;
  }
  
}
.participants {
    display: flex;
    flex-direction: column;
    width: 320px;
    background: #2B2A2A;
    color: #FFFFFF;
    border-radius: 16px;
    overflow: hidden;
    height: 390px;
    justify-content: space-between;
    box-shadow: 2px 2px 10px #7d7c7c;

    .participants-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #ffffff;
        background: #4d4c4a;
        padding: 10px;
        height: 34px;

        h4 {
            padding: 0;
            margin: 0
        }

        a {
            display: block;
            cursor: pointer;
            width: 22px;
            height: 22px;

            svg {
                color: #FFFFFF;
            }
        }
    }

    .participants-content {
        flex: 1;
        display: flex;
        flex-direction: column;



        .search {
            height: 24px;
            padding: 2px 5px;
            text-align: center;
            margin-top: 15px;
            display: flex;
            background: #4d4c4a;
            border: 1px solid #4d4c4a;
            border-radius: 15px;
            justify-content: center;
            align-items: center;
            margin: 15px 15px 0 15px;

            input {
                width: 92%;
                height: 90%;
                padding: 0 5px;
                background: #4d4c4a;
                transition: background-color 0.3s ease;
                color: #fff;
                border: none;
                outline: none;
                padding-inline: 0;
                padding-block: 0;
                padding-left: 2px;
                box-shadow: none;
            }

            input:hover {
                background-color: #4d4c4a;
                color: #fff;
            }

            input::placeholder {
                color: gray;
                opacity: 1;
            }

            input:focus {
                background-color: #4d4c4a;
                color: #fff;
                outline: none;
            }


        }

        .list-title {
            padding: 15px 0 15px 15px;
            display: flex;
            justify-content: space-between;

            .title-count {
                flex: 1;
            }

            .icons {
                width: 100px;
                display: flex;
                justify-content: right;
                padding-right: 5px;

                .batch-group {
                    display: flex;
                    gap: 8px;
                    margin-top: 2px;
                    margin-right: 6px;

                    .allow {
                        svg {
                            cursor: pointer;
                            width: 20px;
                            height: 20px;
                        }
                    }

                    .reject {
                        svg {
                            cursor: pointer;
                            width: 17px;
                            height: 17px;
                        }
                    }


                }


                .btn-toggle-view {

                    svg {
                        cursor: pointer;
                        width: 22px;
                        height: 22px;
                    }
                }

                .btn-toggle-view:hover {
                    opacity: 0.8;
                }


            }



        }

        .member-list {
            overflow: auto;
            padding: 0 10px;
            display: flex;
            height: 170px;
            flex-direction: column;

            &-item {
                color: #ffffff;
                padding: 5px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;

                .user-name {
                    text-align: left;
                    width: 180px;
                    flex-grow: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: flex;
                    justify-content: left;
                    align-items: center;
                }

                .actions {
                    display: flex;
                    gap: 6px;

                    .remove-member {
                        padding-right: 3px;

                        svg {
                            height: 0.9rem;
                            width: 0.9rem;
                        }
                    }

                    .btn-host {
                        cursor: pointer;
                    }
                }

                svg {
                    height: 1.25rem;
                    width: 1.25rem;
                }
            }


        }


        .member-grid {
            overflow: auto;
            padding: 0 10px;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            column-gap: 10px;
            height: 170px;


            &-item {

                margin-bottom: 10px;
                flex-shrink: 0;
                display: flex;
                flex-direction: column;
                border-radius: 8px;
                border: 1px solid #7d7c7c;

                background: #fff;
                display: flex;

                .item-canvas {
                    cursor: pointer;
                    flex: 1;
                }


                .item-user-name {
                    color: #000;
                    margin-top: 15px;
                    text-align: center;
                    height: 30px;

                }

            }






        }

        .un-checked-member {
            opacity: 0.2;
            background-color: gray;
            cursor: pointer;
        }
    }

    .participants-footer {
        margin: 0 20px;
        border-top: 1px solid #FFFFFF;
        display: flex;
        padding: 10px;
        justify-content: center;
        gap: 10px;
        font-size: 12px;

        .normal {
            cursor: pointer;
        }

        .disable {
            cursor: not-allowed;
            opacity: 0.6;
        }

        .batch-btn {
            background: #1B1B1B;
            position: relative;

            .small-bubble {
                position: absolute;
                height: 20px;
                width: 20px;
                top: -5px;
                right: -5px;
                background: red;
                border-radius: 10px;
                font-size: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                text-align: center
            }

            svg {
                width: 1.5rem;
                height: 1.25rem;
            }
        }

        .footer-button {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 34px;
            width: 100px;
            padding: 10px 5px;
            border-radius: 8px;


        }

        .video-call {
            svg {
                width: 1.5rem;
                height: 1.5rem;
            }
        }


    }
}
.school-folder {
  display: flex;
  flex-direction: column;
  min-height: 560px;

  .list {
    width: 100%;
    align-self: flex-end;
    min-height: 60px;
    display: flex;
    justify-content: center;

    .loading {
      border: 4px solid #f3f3f3;
      border-radius: 50%;
      border-top: 4px solid #3498db;
      width: 20px;
      height: 20px;
      -webkit-animation: spin 2s linear infinite;
      /* Safari */
      animation: spin 2s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

  }

  .ant-list-items {
    display: flex;
    flex-wrap: wrap;
  }

  .table-container {
    width: 100%;
    overflow-x: auto;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
  }

  .table th,
  .table td {
    padding: 12px;
    font-size: 14px;
    text-align: left;
  }

  .hoverable-name {
    cursor: pointer;
    transition: color 0.3s ease;
  }

}
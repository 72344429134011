.draggable-panel {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
  right: 0;
  bottom: 0;
  margin: auto;
  //border: 1px solid #ccc;

  box-shadow: 0;
}
.draggable-panel-hide {
  display: none;
}

.draggable-panel-move {
  position: fixed;
  z-index: 100;
  //border: 1px solid #ccc;
}

.draggable-panel-resize {
  position: fixed;
  z-index: 100;
}

.panel__container {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  overflow: hidden;
  background: #ccc;
  box-shadow: 2px 2px 10px #7d7c7c;
  touch-action: none;
}

.panel__header {
  background-color: black;
  padding: 5px;
  color: #ffffff;
}
.panel__inner {
  width: 100%;
  height: 100%;
  z-index: 101;
  position: absolute;
  cursor: move;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  touch-action: none;
}
.panel__inactive {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  padding: 0.8rem 1rem;
  border-radius: 5px;
  border: 2px solid #4285f4;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.6px;
  font-family: "Assistant";
  a.interactive-button {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border: 1px solid #838383;
    border-radius: 5px;
    transition: background 0.3s ease, color 0.3s ease;
  }

  a.interactive-button:hover {
    background-color: #7b7b7b;
    color: #000;
  }
}
.panel__content {
  display: flex;
  flex-grow: 1;
  // padding-right: 2px;
  // padding-bottom: 2px;
}

.resize-bar {
  display: flex;
  align-items: center;
  gap: 20px;
  a.interactive-button-resize {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    padding: 0.25rem 0.25rem;
    border: 1px solid #838383;
    border-radius: 5px;
    transition: background 0.3s ease, color 0.3s ease;
  }

  a.interactive-button-resize:hover {
    background-color: #7b7b7b;
    color: #000;
  }

  svg {
    width: 2.0rem;
  }

  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .icon-label {
    margin-top: 5px;
    font-size: 10px;
    color: #fff;
  }
}

.page-notes-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 46px);


  .rich-note-tool-bar {
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: center;
  }

  .note-content {
    flex: 1;
    background-color: rgb(245, 245, 245);
    // background-color: green;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;


    .note-content-main {
      height: 85%;
      width: 768px;
      margin: 0;
      background-color: #fff;
      padding: 40px 50px 20px 50px;
      border: 1px solid #e8e8e8;
      box-shadow: 0 2px 10px rgb(0 0 0 / 12%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow-y: hidden;

      @media (max-width: 900px) {
        width: 80%;
      }

      .note-content-main-title {
        padding: 10px 0;
        border-bottom: 1px solid #e8e8e8;



        textarea {
          font-size: 30px;
          font-weight: 500;
          border: 0;
          width: 100%;
          line-height: 1;
          outline: none;
          padding-inline: 0;
          padding-block: 0;
          padding-left: 2px;
          box-shadow: none;
        }

        .time {
          margin-top: 20px;
          margin-bottom: 5px;
          color: #ccc;
        }
      }

      .note-content-main-rich-edit {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: hidden;

      }

    }



  }

}
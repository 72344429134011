@import "../../css/variables.module";

.RoomDialog-Panel {
  padding: 5px;
  font-size: 16px;
}

.imago {
  .RoomDialog__button {
    border: 1px solid var(--default-border-color) !important;
  }

  .RoomDialog-linkContainer {
    display: flex;
    margin: 1.5em 0;
  }

  input.RoomDialog-link {
    color: var(--text-primary-color);
    cursor: pointer;
    border: none;
    width: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    border-radius: var(--space-factor);
    background-color: var(--button-gray-1);
    outline: none;
    height: 45px;
  }

  #txt-copy-success {
    all: initial;
    color: #ffffff;
    cursor: pointer;
    border: none;
    width: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    border-radius: var(--space-factor);
    background-color: green;
    outline: none;
    height: 45px;
    padding-left: 15px;
  }


  .RoomDialog-emoji {
    font-family: sans-serif;
  }

  .RoomShareContainer {
    display: flex;
    justify-content: space-between;

    box-sizing: border-box;
    margin-top: 15px;
  }


  .share-left {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 10px;
  }

  .share-buttons {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-around;
    margin-bottom: 0.5rem;
    padding-left: 0.5rem;
    gap: 20px;
  }

  .share-buttons>div {
    margin-right: 1.5rem;
    cursor: pointer;
  }

  .RoomShareContainer>* {
    margin-right: 15px;
  }


  .share-panel-title {
    font-size: 1.375rem;
    font-Weight: bolder;
    margin-bottom: 1rem;
  }

  .share-content-title {
    font-size: 1rem;
    font-Weight: bold;
    height: 1.5rem;
    margin-top: 0.5rem;
  }

  .share-content-body {
    padding-left: 0.5rem;
  }

  .qrCode-title {
    font-size: 1.375rem;
    font-Weight: bolder;
    margin-bottom: 1rem;
    text-align: center;
  }

  .qrCode_container {
    margin-top: 0.5rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 296px) {
      display: none;
    }
  }

  .room-id {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 10px 0;
    font-weight: bold;
  }

  .RoomDialog-usernameContainer {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    margin: 25px 0;

    @include isMobile {
      align-items: stretch;
    }
  }

  @include isMobile {
    .RoomDialog-usernameLabel {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .RoomDialog-username {
    background-color: var(--input-bg-color);
    border-color: var(--input-border-color);
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    margin: 10px 0;
    font-size: 1em;
  }

  .RoomDialog-sessionStartButtonContainer {
    display: flex;
    justify-content: center;
  }

  .Modal .RoomDialog-stopSession {
    background-color: var(--button-destructive-bg-color);

    .ToolIcon__label,
    .ToolIcon__icon svg {
      color: var(--button-destructive-color);
    }
  }

  .google-meet-title {
    margin: 0;
    padding: 0;
    font-size: 1rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 5px;
      vertical-align: middle;
    }

    span {
      font-weight: normal;
    }
  }


  .room-info {
    display: flex;
    justify-content: space-around;

    width: 100%;

    .info-left {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      flex-grow: 1;
    }

    .info-right {
      display: flex;
      border-left: 1px solid #ccc;
      width: 100%;
      flex-grow: 1;

      p {
        margin: 0;
        padding: 0;
        line-height: 2rem;
      }
    }


  }

  .room-info-mobile {
    flex-direction: column;

    .info-right {
      border-left: 0;
      padding-top: 20px;
      border-top: 1px solid #ccc;
    }
  }
}
.right-side-container {
  width: 500px;
  position: absolute;
  z-index: 10;
  right: 10px;
  top: 0;
  display: flex;
  height: calc(100% - 130px);
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background-color: #FAF3F0;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  overflow: hidden;


  .side-box-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;

    .header-left {
      display: flex;

      .go-history {
        display: flex;
        justify-content: left;
        align-items: center;
        width: 20px;
        box-sizing: border-box;
        height: 20px;
        margin-right: 5px;


        .icon {
          cursor: pointer;
          display: flex;
          flex: 1;
          justify-content: left;
          align-items: center;
          box-sizing: border-box;
          height: 20px;
        }
      }

      .title {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
      }
    }



    .close {
      cursor: pointer;
    }
  }

  .side-box-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 5px 10px;
    width: 100%;
    overflow: hidden;


    .ai-assist {
      padding: 0 10px;

      .txt-label {
        font-size: 16px;
        font-weight: bold;
        margin: 15px;
      }


      .txt-input {
        height: 30px;
        padding: 2px 0;
        text-align: center;
        margin-top: 25px;
        display: flex;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: center;
        align-items: center;

        svg {
          width: 20px;
          height: 20px;
        }

        .left-tools {
          margin-left: 5px;
          border-right: 1px solid #ccc;
          padding: 5px;
          display: flex;
          justify-content: center;
          align-items: center;

          .upload-image {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }


        }



        input {
          margin-left: 5px;
          flex: 1;
          height: 90%;
          padding: 0 5px;
          background: #fff;
          transition: background-color 0.3s ease;
          border: none;
          outline: none;
          padding-inline: 0;
          padding-block: 0;
          padding-left: 2px;
          box-shadow: none;
        }

        .right-send-msg {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          cursor: pointer;
        }

      }


      .actions-group {
        margin-top: 40px;
        flex: 1;
        display: grid;
        grid-template-columns: repeat(3, auto);
        gap: 15px;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #fff;
          border-radius: 5px;
          height: 40px;
          padding: 5px 0;
          cursor: pointer;
        }
      }

    }


    .ai-chat {
      display: flex;
      flex: 1;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;

      .content-main {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        overflow: hidden;

        .send-box {
          width: 100%;
          overflow: hidden;

          .txt-input {
            height: 30px;
            padding: 2px 0;
            text-align: center;
            display: flex;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            justify-content: center;
            align-items: center;

            svg {
              width: 20px;
              height: 20px;
            }

            .left-tools {
              margin-left: 5px;
              border-right: 1px solid #ccc;
              padding: 5px;
              display: flex;
              justify-content: center;
              align-items: center;

              .upload-image {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              }


            }



            input {
              margin-left: 5px;
              flex: 1;
              height: 90%;
              padding: 0 5px;
              background: #fff;
              transition: background-color 0.3s ease;
              border: none;
              outline: none;
              padding-inline: 0;
              padding-block: 0;
              padding-left: 2px;
              box-shadow: none;
            }

            .right-send-msg {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 10px;
              cursor: pointer;
            }

          }

        }

        .message-box {
          flex: 1;
          width: 100%;
          overflow: auto;
          margin-top: 10px;
          padding: 0 5px;
          box-sizing: border-box;

          .item {
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;

            .item-user {
              display: flex;
              justify-content: end;
              gap: 10px;

              .buttons {
                flex: 1;
                margin: 0;
                display: flex;
                align-items: flex-end;
                justify-content: end;
                box-sizing: border-box;
                gap: 10px;
                padding: 0;

                div {
                  // display: flex;
                  // align-items: flex-end;
                  cursor: pointer;

                  svg {
                    width: 15px;
                    height: 15px;
                  }
                }

              }

              .content {
                flex: 5;
                background-color: #EFF0FA;
                border-radius: 5px;
                padding: 5px;
                word-wrap: break-word;
                overflow: auto;
              }

              .avatar {
                .icon {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 30px;
                  height: 30px;
                  border-radius: 15px;
                  background-color: #FCE38A;
                }
              }

            }

            .item-ai {
              display: flex;
              justify-content: start;
              gap: 10px;
              margin-top: 25px;

              .avatar {
                .icon {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 30px;
                  height: 30px;
                  border-radius: 15px;
                  background-color: #354052;
                  color: #ffffff;
                }
              }

              .content {
                flex: 5;
                background-color: #ffffff;
                border-radius: 5px;
                padding: 5px;
                word-wrap: break-word;
                overflow: auto;
              }

              .buttons {
                flex: 1;
                margin: 0;
                padding: 0;
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                box-sizing: border-box;
                gap: 10px;


                div {
                  // display: flex;
                  // align-items: flex-end;
                  cursor: pointer;

                  svg {
                    width: 15px;
                    height: 15px;
                  }
                }

              }

            }
          }
        }
      }

    }


    .ai-analyze-image {
      display: flex;
      flex: 1;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;

      .content-main {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        overflow: hidden;

        .send-box {
          width: 100%;
          overflow: auto;

          .send-box-content {
            padding: 5px 10px;
            text-align: center;
            display: flex;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            justify-content: center;
            align-items: center;
            overflow: auto;
            flex-direction: column;

            .upload-img-pre-list {
              display: flex;
              justify-content: flex-start;
              padding: 2px;
              width: 100%;
              box-sizing: border-box;
              margin-bottom: 15px;


              .pre-img-item {
                position: relative;

                img {
                  width: auto;
                  height: 50px;
                }

                .remove-pre-img {
                  position: absolute;
                  right: 2px;
                  top: 2px;
                  cursor: pointer;

                  svg {
                    height: 16px;
                    width: 16px;
                  }
                }
              }


            }


            textarea {
              font-weight: 500;
              border: 0;
              width: 100%;
              line-height: 1;
              outline: none;
              padding-inline: 0;
              padding-block: 0;
              padding-left: 2px;
              box-shadow: none;
            }

            .send-tools {
              width: 100%;
              display: flex;
              justify-content: space-between;

              .tools-left {
                display: flex;
                justify-content: flex-start;
                padding-left: 5px;

                svg {
                  width: 16px;
                  height: 16px;
                }
              }

              .tools-right {
                display: flex;
                justify-content: flex-end;
                box-sizing: border-box;
                padding-right: 5px;
              }

            }


          }

        }

        .message-box {
          flex: 1;
          width: 100%;
          overflow: auto;
          margin-top: 10px;
          padding: 0 5px;
          box-sizing: border-box;

          .item {
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;

            .item-uploaded-img {
              display: flex;
              justify-content: end;
              margin-bottom: 10px;

              img {
                width: 200px;
                height: auto;
              }
            }

            .item-user {
              display: flex;
              justify-content: end;
              gap: 10px;

              .buttons {
                flex: 1;
                margin: 0;
                display: flex;
                align-items: flex-end;
                justify-content: end;
                box-sizing: border-box;
                gap: 10px;
                padding: 0;

                div {
                  display: flex;
                  align-items: flex-end;
                  cursor: pointer;

                  svg {
                    width: 15px;
                    height: 15px;
                  }
                }

              }

              .content {
                flex: 5;
                background-color: #EFF0FA;
                border-radius: 5px;
                padding: 5px;
                word-wrap: break-word;
                overflow: auto;
              }

              .avatar {
                .icon {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 30px;
                  height: 30px;
                  border-radius: 15px;
                  background-color: #FCE38A;
                }
              }

            }

            .item-ai {
              display: flex;
              justify-content: start;
              gap: 10px;
              margin-top: 25px;

              .avatar {
                .icon {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 30px;
                  height: 30px;
                  border-radius: 15px;
                  background-color: #354052;
                  color: #ffffff;
                }
              }

              .content {
                flex: 5;
                background-color: #ffffff;
                border-radius: 5px;
                padding: 5px;
                word-wrap: break-word;
                overflow: auto;
              }

              .buttons {
                flex: 1;
                margin: 0;
                padding: 0;
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                box-sizing: border-box;
                gap: 10px;


                div {
                  display: flex;
                  align-items: flex-end;
                  cursor: pointer;

                  svg {
                    width: 15px;
                    height: 15px;
                  }
                }

              }

            }
          }
        }
      }

    }


    .ai-summarize {
      display: flex;
      flex: 1;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;

      .content-main {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        overflow: hidden;

        .send-box {
          width: 100%;
          overflow: auto;

          .send-box-content {
            padding: 5px;
            text-align: center;
            display: flex;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            justify-content: center;
            align-items: center;
            overflow: auto;
            flex-direction: column;


            textarea {
              font-weight: 500;
              border: 0;
              width: 100%;
              line-height: 1;
              outline: none;
              padding-inline: 0;
              padding-block: 0;
              padding-left: 2px;
              box-shadow: none;
            }

            .send-tools {
              width: 100%;
              display: flex;
              justify-content: space-between;

              .tools-left {
                display: flex;
                justify-content: flex-start;
              }

              .tools-right {
                display: flex;
                justify-content: flex-end;
                box-sizing: border-box;
                padding-right: 5px;
              }

            }


          }

        }

        .message-box {
          flex: 1;
          width: 100%;
          overflow: auto;
          margin-top: 10px;
          padding: 0 5px;
          box-sizing: border-box;
          overflow: hidden;
          display: flex;

          .result {
            flex: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 30px;
            overflow: hidden;


            .title {
              padding-left: 2px;
              color: #ACA59D;
              margin-bottom: 5px;
            }

            .content {
              flex: 1;
              background-color: #fff;
              border-radius: 10px;
              overflow: auto;
              padding: 5px;

              img {
                width: 600px;
                height: 800px;
              }
            }

          }
        }
      }

    }


    .ai-translate {
      display: flex;
      flex: 1;
      width: 100%;
      justify-content: center;
      align-items: center;

    }


    .ai-read-article {
      display: flex;
      flex: 1;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;

      .content-main {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 25px;
        box-sizing: border-box;

        .send-box {
          width: 100%;
          overflow: hidden;
          flex: 1;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .send-box-content {
            padding: 5px;
            text-align: center;
            display: flex;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            flex-direction: column;
            flex: 1;



            textarea {
              font-weight: 500;
              border: 0;
              width: 100%;
              line-height: 1;
              outline: none;
              padding-inline: 0;
              padding-block: 0;
              padding-left: 2px;
              box-shadow: none;
              flex: 1;
            }

            .send-tools {
              width: 100%;

              display: flex;
              justify-content: space-between;

              .tools-left {
                display: flex;
                justify-content: flex-start;
              }

              .tools-right {
                display: flex;
                justify-content: flex-end;
                box-sizing: border-box;
                padding-right: 5px;
              }

            }


          }

          .result {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            height: 50px;

          }

        }


      }

    }



    .ai-quiz-and-assessment {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;

      .content-main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        overflow: auto;
        box-sizing: border-box;
        padding: 0 5px;

        .send-box {
          width: 100%;

          .send-box-content {
            padding: 5px;
            text-align: center;
            display: flex;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            justify-content: center;
            align-items: center;
            overflow: auto;
            flex-direction: column;
            gap: 15px;
            padding: 20px;

            .form-item {
              display: flex;
              flex-direction: column;
              width: 100%;
              align-items: flex-start;
              gap: 8px;

              label {
                font-weight: bold;
              }
            }

            .btn-group {
              margin: 10px 0;
              width: 100%;
              display: flex;
              justify-content: space-between;

              .left {
                flex: 1;
                display: flex;
                justify-content: flex-start;

                .btn-clear {
                  height: 20px;
                  background-color: #fff;
                  border-radius: 5px;
                  padding: 5px 10px;
                  color: #000;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                  border: 1px solid #ccc;
                }
              }

              .right {
                flex: 1;
                display: flex;
                justify-content: flex-end;

                .btn-submit {
                  height: 25px;
                  background-color: #4285F4;
                  border-radius: 5px;
                  padding: 5px 10px;
                  color: #fff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                }
              }
            }


          }

        }

        .message-box {
          width: 100%;

          margin-top: 10px;
          padding: 0;
          box-sizing: border-box;

          flex-direction: column;
          display: flex;



          .result {

            display: flex;
            flex-direction: column;
            margin-top: 30px;
            box-sizing: border-box;
            justify-content: space-between;


            .title {
              padding-left: 2px;
              color: #ACA59D;
              margin-bottom: 10px;
            }

            .content {
              background-color: #fff;
              border-radius: 10px;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 10px 5px 10px 10px;
              flex: 1;
              box-sizing: border-box;
              flex-direction: column;
              min-height: 80px;
            }

          }


        }
      }

    }


    .ai-learning-companion {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;

      .content-main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        overflow: auto;
        box-sizing: border-box;
        padding: 0 5px;

        .send-box {
          width: 100%;

          .send-box-content {
            padding: 5px;
            text-align: center;
            display: flex;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            justify-content: center;
            align-items: center;
            overflow: auto;
            flex-direction: column;
            gap: 15px;
            padding: 20px;

            .form-item {
              display: flex;
              flex-direction: column;
              width: 100%;
              align-items: flex-start;
              gap: 8px;

              label {
                font-weight: bold;
              }
            }

            .btn-group {
              margin: 10px 0;
              width: 100%;
              display: flex;
              justify-content: space-between;

              .left {
                flex: 1;
                display: flex;
                justify-content: flex-start;

                .btn-clear {
                  height: 20px;
                  background-color: #fff;
                  border-radius: 5px;
                  padding: 5px 10px;
                  color: #000;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                  border: 1px solid #ccc;
                }
              }

              .right {
                flex: 1;
                display: flex;
                justify-content: flex-end;

                .btn-submit {
                  height: 25px;
                  background-color: #4285F4;
                  border-radius: 5px;
                  padding: 5px 10px;
                  color: #fff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                }
              }
            }


          }

        }

        .message-box {
          width: 100%;
          margin-top: 10px;
          padding: 0;
          box-sizing: border-box;
          flex-direction: column;
          display: flex;



          .result {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            box-sizing: border-box;
            justify-content: space-between;


            .title {
              padding-left: 2px;
              color: #ACA59D;
              margin-bottom: 10px;
            }

            .content {
              background-color: #fff;
              min-height: 30px;
              border-radius: 10px;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 10px 5px 10px 10px;
              flex: 1;
              box-sizing: border-box;
              flex-direction: column;
              min-height: 80px;
            }

          }


        }
      }

    }


    .ai-vocabulary-list {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;

      .content-main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        overflow: auto;
        box-sizing: border-box;
        padding: 0 5px;

        .send-box {
          width: 100%;

          .send-box-content {
            padding: 5px;
            text-align: center;
            display: flex;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            justify-content: center;
            align-items: center;
            overflow: auto;
            flex-direction: column;
            gap: 15px;
            padding: 20px;

            .form-item {
              display: flex;
              flex-direction: column;
              width: 100%;
              align-items: flex-start;
              gap: 8px;

              label {
                font-weight: bold;
              }
            }

            .btn-group {
              margin: 10px 0;
              width: 100%;
              display: flex;
              justify-content: space-between;

              .left {
                flex: 1;
                display: flex;
                justify-content: flex-start;

                .btn-clear {
                  height: 20px;
                  background-color: #fff;
                  border-radius: 5px;
                  padding: 5px 10px;
                  color: #000;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                  border: 1px solid #ccc;
                }
              }

              .right {
                flex: 1;
                display: flex;
                justify-content: flex-end;

                .btn-submit {
                  height: 25px;
                  background-color: #4285F4;
                  border-radius: 5px;
                  padding: 5px 10px;
                  color: #fff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                }
              }
            }


          }

        }

        .message-box {
          width: 100%;

          margin-top: 10px;
          padding: 0;
          box-sizing: border-box;

          flex-direction: column;
          display: flex;



          .result {

            display: flex;
            flex-direction: column;
            margin-top: 30px;
            box-sizing: border-box;
            justify-content: space-between;


            .title {
              padding-left: 2px;
              color: #ACA59D;
              margin-bottom: 10px;
            }

            .content {
              background-color: #fff;
              border-radius: 10px;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 10px 5px 10px 10px;
              flex: 1;
              box-sizing: border-box;
              flex-direction: column;
              min-height: 80px;
            }

          }


        }
      }

    }


    .ai-lesson-plan {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;

      .content-main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        overflow: auto;
        box-sizing: border-box;
        padding: 0 5px;


        .send-box {
          width: 100%;

          .send-box-content {
            padding: 5px;
            text-align: center;
            display: flex;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            justify-content: center;
            align-items: center;
            overflow: auto;
            flex-direction: column;
            gap: 15px;
            padding: 20px;

            .form-item {
              display: flex;
              flex-direction: column;
              width: 100%;
              align-items: flex-start;
              gap: 8px;

              label {
                font-weight: bold;
              }
            }

            .btn-group {
              margin: 10px 0;
              width: 100%;
              display: flex;
              justify-content: space-between;

              .left {
                flex: 1;
                display: flex;
                justify-content: flex-start;

                .btn-clear {
                  height: 20px;
                  background-color: #fff;
                  border-radius: 5px;
                  padding: 5px 10px;
                  color: #000;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                  border: 1px solid #ccc;
                }
              }

              .right {
                flex: 1;
                display: flex;
                justify-content: flex-end;

                .btn-submit {
                  height: 25px;
                  background-color: #4285F4;
                  border-radius: 5px;
                  padding: 5px 10px;
                  color: #fff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                }
              }
            }


          }

        }

        .message-box {
          width: 100%;

          margin-top: 10px;
          padding: 0;
          box-sizing: border-box;

          flex-direction: column;
          display: flex;



          .result {

            display: flex;
            flex-direction: column;
            margin-top: 30px;
            box-sizing: border-box;
            justify-content: space-between;


            .title {
              padding-left: 2px;
              color: #ACA59D;
              margin-bottom: 10px;
            }

            .content {
              background-color: #fff;
              border-radius: 10px;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 10px 5px 10px 10px;
              flex: 1;
              box-sizing: border-box;
              flex-direction: column;
              min-height: 80px;
            }

          }


        }
      }

    }


    .ai-text-to-image {
      display: flex;
      flex: 1;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;

      .content-main {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        overflow: hidden;

        .send-box {
          width: 100%;
          overflow: auto;

          .send-box-content {
            padding: 5px;
            text-align: center;
            display: flex;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            justify-content: center;
            align-items: center;
            overflow: auto;
            flex-direction: column;


            textarea {
              font-weight: 500;
              border: 0;
              width: 100%;
              line-height: 1;
              outline: none;
              padding-inline: 0;
              padding-block: 0;
              padding-left: 2px;
              box-shadow: none;
            }

            .send-tools {
              width: 100%;
              display: flex;
              justify-content: space-between;

              .tools-left {
                display: flex;
                justify-content: flex-start;
              }

              .tools-right {
                display: flex;
                justify-content: flex-end;
                box-sizing: border-box;
                padding-right: 5px;
              }

            }


          }

        }

        .message-box {
          flex: 1;
          width: 100%;
          overflow: hidden;
          margin-top: 10px;
          padding: 0 5px;
          box-sizing: border-box;
          overflow: hidden;
          flex-direction: column;
          display: flex;

          .img-type-box {
            margin: 10px 0;

            .title {
              padding-left: 2px;
              color: #ACA59D;
              margin-bottom: 5px;
            }

            .img-type-list {

              width: 100%;
              list-style: none;
              display: flex;
              overflow-x: scroll;

              .img-type-item {

                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                margin: 0;
                height: 90px;
                width: 78px;

                li {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                  position: relative;
                  box-sizing: border-box;
                  padding: 0;
                  margin: 0;

                  img {
                    width: 64px;
                    height: 80px;
                    padding: 2px;
                    border-radius: 8px;
                  }

                  span {
                    color: #fff;
                    position: absolute;
                    font-size: 12px;
                    font-weight: bold;
                    bottom: 8px;
                    text-align: center;
                  }
                }

                .select-type {
                  border: 1px solid rgb(165, 165, 165);
                }
              }

            }
          }




          .result {
            flex: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            overflow: hidden;


            .title {
              padding-left: 2px;
              color: #ACA59D;
              margin-bottom: 5px;
            }

            .content {
              background-color: #fff;
              border-radius: 10px;
              overflow: auto;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 20px;
              flex: 1;

              img {
                width: auto;
                height: 100%;
              }
            }

          }


        }
      }

    }

    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;

      .btn {
        height: 25px;
        background-color: #4285F4;
        border-radius: 5px;
        padding: 5px 10px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

    }


    .tools-bar-icon {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 10px;
      gap: 10px;

      div {
        // display: flex;
        // align-items: flex-end;
        cursor: pointer;

        svg {
          width: 15px;
          height: 15px;
        }
      }

    }



  }


}
.mermain-content{
    display: flex;
    justify-content:space-between;
    flex-wrap: wrap;
    gap:10px; 
    width: 100%;
    height:100%;
    .graph{
        display:flex;
        flex-direction:column;
        overflow:auto;
        flex-grow: 1;
        .graphDiv{
            overflow: auto;
            width: 100%;
            height: 100%;
        }
        svg{
            width: 100%;
            height: 100%;
        }
    }
    .render{
        display: flex;
        flex-direction: column;
        gap:5px;
        width: 100%;
        max-width: 350px;
    }
}
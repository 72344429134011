@import "open-color/open-color.scss";

@mixin isMobile() {
  @at-root .imago--mobile#{&} {
    @content;
  }
}

@mixin toolbarButtonColorStates {
  &.fillable {
    .ToolIcon_type_radio,
    .ToolIcon_type_checkbox {
      &:checked + .ToolIcon__icon {
        --icon-fill-color: var(--color-primary-darker);

        svg {
          fill: var(--icon-fill-color);
        }
      }
    }
  }

  .ToolIcon_type_radio,
  .ToolIcon_type_checkbox {
    &:checked + .ToolIcon__icon {
      background: var(--color-primary-light);
      --keybinding-color: var(--color-gray-60);

      svg {
        color: var(--color-primary-darker);
      }
    }
  }

  .ToolIcon__keybinding {
    bottom: 4px;
    right: 4px;
  }

  .ToolIcon__icon {
    &:hover {
      background: var(--button-hover);
    }

    &:active {
      background: var(--button-hover);
      border: 1px solid var(--color-primary-darkest);
    }
  }
}

@mixin outlineButtonStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem;
  width: var(--default-button-size);
  height: var(--default-button-size);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: var(--default-border-color);
  border-radius: var(--border-radius-lg);
  cursor: pointer;
  background-color: transparent;
  color: var(--text-primary-color);

  &:hover {
    background-color: var(--button-hover);
  }

  &:active {
    background-color: var(--button-hover);
    border-color: var(--color-primary-darkest);
  }

  &.active {
    background-color: var(--color-primary-light);
    border-color: var(--color-primary-light);

    &:hover {
      background-color: var(--color-primary-light);
    }

    svg {
      color: var(--color-primary-darker);
    }
  }
}

$theme-filter: "invert(93%) hue-rotate(180deg)";
$right-sidebar-width: "302px";

:export {
  themeFilter: unquote($theme-filter);
  rightSidebarWidth: unquote($right-sidebar-width);
}

.page-sticky-note-content {
    display: flex;
    width: 100%;
    background-color: #fff;
    flex-direction: column;
    overflow: hidden;

    .translate-part {
        width: 240px;
        height: 100%;
    }

    flex: 1;

    .header {
        display: flex;
        justify-content: end;
        align-items: center;
        padding-top: 2px;
        padding-right: 5px;

    }

    .content {
        width: 100%;
        display: flex;
        overflow: hidden;
        flex: 1
    }

}
.ai-images-searchbox{
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 8px;

    button{
        width: 2.25rem !important;
        height: 2.25rem  !important;
        padding: 0  !important;
        border: 0  !important;
    }
}

.ai-images-searchresults{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    img{
        width: 200px;
    }
}

table.gsc-search-box td.gsc-input{
    padding: 0 !important;
  }
  .gsc-control-cse{margin:0; padding: 0 !important;}
  .gsc-input-box{height: 2.125em !important;}
  .gsib_a{padding: 0 !important;}
  
  input.gsc-input{ height: 2em !important; margin: 1px !important;}
  .gsc-search-button{
    margin-left: 8px !important;
  }
  .gsc-search-button-v2{
    height: 36px !important;
    width: 36px !important;
    padding: 0 !important;
   // background-color: #5B57D1 !important;
    border-radius:10px  !important;
    background-image: url("../assets/images/bt_search_google.png") !important;
    svg{
        display: none !important;
    }
  }
  
  .gsc-tabsArea,.gsc-adBlock,.gs-spelling{
    display:none !important;
  }
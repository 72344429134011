@import "../css/variables.module";

.profile {
  position: relative;

  a {
    cursor: pointer;
  }

  a:hover {
    font-weight: bold !important;
  }

  .Island {
    padding: 0 !important;
  }

  .Modal__content {
    max-width: var(--max-width) !important;
    position: relative !important;
  }

  .focal-point {
    cursor: pointer;
  }

  .header {
    height: 48px;
    // background: #58d9fd;
    position: relative;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;

    .img {
      position: absolute;
      bottom: -25px;
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    img {
      width: 50px;
    }
  }

  .body {
    //background: var(--modal-background-color);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding-bottom: 16px;
    padding-left: 30px;
    padding-right: 30px;

    .user-name {
      font-size: 20px;
      font-weight: bolder;
      padding-top: 30px;
    }

    .user-name,
    .email {
      width: 8rem;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: wrap;
      white-space: nowrap;
    }

    .body-title {
      font-size: 20px;
      font-weight: bolder;
      padding-top: 30px;
    }

    .body-desc {
      font-size: 12px;
      text-align: center;
    }
  }

  .footer {
    // background: #edfcff;
    border-top: 1px solid #d9d9d9;
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 10px;
    padding: 10px 5px;
    flex-wrap: wrap;

    img {
      width: 24px;
    }

    a {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      color: #000000;
      gap: 5px;
      text-decoration: none;
      font-weight: normal;

      svg {
        width: 1.5rem;
        fill: #000000;
      }

      font-size: 1rem;
      padding-left: 20px;
    }

    .footer-right {
      display: flex;
      gap: 5px;
      flex-grow: 2;
      justify-content: end;
    }
  }

  .user-plan {
    background-color: #2657b0;
    color: #ffffff !important;
    font-size: 0.8rem !important;
    border-radius: 20px;
    padding: 3px 10px;
    font-weight: bold;
    line-height: 1.5rem !important;
  }

  button {
    background-color: #ffffff;
    min-width: 70px;
    width: auto;
    height: 24px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }

  .btn-delete {
    color: #ff0000;
  }

  .btn-primary {
    background-color: #2657b0;
    color: #ffffff;
    height: 24px;
  }

  .secondary-menu {
    background-color: var(--modal-background-color);
    padding: 10px !important;
    width: 160px;

    .menu-list {
      width: 100%;

      ul,
      li {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      li {
        height: 2rem;
      }
    }
  }
}

.modal-close {
  text-align: right;
  display: flex;
  justify-content: end;
  padding: 5px;

  svg {
    width: 1rem;
    height: 1rem;
    fill: black;
  }
}
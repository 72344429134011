@import "../css/variables.module";

// container in body where the actual tooltip is appended to
.imago-tooltip {
  position: fixed;
  z-index: 1000;

  padding: 8px;
  border-radius: 6px;
  box-sizing: border-box;
  pointer-events: none;
  word-wrap: break-word;

  background: $oc-black;

  line-height: 1.5;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: $oc-white;

  display: none;

  &.imago-tooltip--visible {
    display: block;
  }
}

// wraps the element we want to apply the tooltip to
.imago-tooltip-wrapper {
  display: flex;
}

.imago-tooltip-icon {
  width: 0.9em;
  height: 0.9em;
  margin-left: 5px;
  margin-top: 1px;
  display: flex;

  @include isMobile {
    display: none;
  }
}

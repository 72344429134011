.imago{
    .market-place{
        display: flex;
        flex-direction: column;
        gap: 20px;

        .card{
            display: flex;
            flex-direction: column;
            gap:15px;
            align-items: center;
            width: 150px;
            height: 180px
        }
    }

    
}
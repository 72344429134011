@import "open-color/open-color";
@import "../css/variables.module";

.imago {
  .layer-ui__wrapper.animate {
    transition: width 0.1s ease-in-out;
  }

  .brush-icon {
    position: absolute;
    pointer-events: none;
    // width: 90px;
    // height: 135px;
    // border-radius: 50%;
    // background-color: red;
    // opacity: 0.5;
    display: none;
  }

  .layer-ui__wrapper {
    // when the rightside sidebar is docked, we need to resize the UI by its
    // width, making the nested UI content shift to the left. To do this,
    // we need the UI container to actually have dimensions set, but
    // then we also need to disable pointer events else the canvas below
    // wouldn't be interactive.
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: var(--zIndex-layerUI);

    &__top-right {
      display: flex;
      gap: 0.75rem;
    }

    &__footer {
      width: 100%;

      &-right,
      &-right-bot,
      &-right-star {
        z-index: 100;
        display: flex;
      }
    }

    .zen-mode-transition {
      transition: transform 0.5s ease-in-out;

      :root[dir="ltr"] &.transition-left {
        transform: translate(-999px, 0);
      }

      :root[dir="ltr"] &.transition-right {
        transform: translate(999px, 0);
      }

      :root[dir="rtl"] &.transition-left {
        transform: translate(999px, 0);
      }

      :root[dir="rtl"] &.transition-right {
        transform: translate(-999px, 0);
      }

      &.layer-ui__wrapper__footer-left--transition-bottom {
        transform: translate(0, 92px);
      }
    }

    .disable-zen-mode {
      height: 30px;
      position: absolute;
      bottom: 10px;

      [dir="ltr"] & {
        right: 15px;
      }

      [dir="rtl"] & {
        left: 15px;
      }

      font-size: 10px;
      padding: 10px;
      font-weight: 500;
      opacity: 0;
      visibility: hidden;
      transition: visibility 0s linear 0s, opacity 0.5s;

      &--visible {
        opacity: 1;
        visibility: visible;
        transition: visibility 0s linear 300ms, opacity 0.5s;
        transition-delay: 0.8s;
      }
    }

    .layer-ui__wrapper__footer-center {
      pointer-events: none;

      & > * {
        pointer-events: all;
      }
    }

    .layer-ui__wrapper__footer-left,
    .layer-ui__wrapper__footer-right,
    .layer-ui__wrapper__footer-right-bot,
    .layer-ui__wrapper__footer-right-star,
    .disable-zen-mode--visible {
      pointer-events: all;
    }

    .layer-ui__wrapper__footer-right {
      margin-top: auto;
      margin-bottom: auto;
    }

    .layer-ui__wrapper__footer-right-star {
      margin-top: auto;
      margin-bottom: auto;

      .glowing-star {
        position: fixed;
        bottom: 350px;
        right: 16px;
        z-index: 3000;
        cursor: pointer;
      }
      
      .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 90%;
        background: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 2000;
      }
      
      .modal-content {
        background: #fff;
        padding: 10px;
        border-radius: 8px;
        width: 400px;
        max-width: 90%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        text-align: center;
        position: relative;
        margin-right: 37px;
        height: 600px;
      }
      
      .modal-close {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 18px;
        cursor: pointer;
      }
    }

    .layer-ui__wrapper__footer-right-bot {
      margin-top: auto;
      margin-bottom: auto;

      .ai-bot {
        position: fixed;
        bottom: 50px;
        right: 16px;
        z-index: 3000;
        cursor: pointer;
      }
      
      .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 2000;
      }
      
      .modal-content {
        background: #fff;
        padding: 10px;
        border-radius: 8px;
        width: 400px;
        max-width: 90%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        text-align: center;
        position: relative;
        margin-right: 16px; 
        height: 610px;
      }
      
      .modal-close {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 18px;
        cursor: pointer;
      }
    }

   
  }
  .left-section {
    // background: #edfcff;
     border-radius: 20px;
     padding-right: 15px;
     display: flex;
     justify-content: center;
     align-items: center;

     a{
       color: #000;
     }
   }
  .flash-collab-win {
    // position: absolute;
    height: 90px;
    width: 160px;
    // top: 10px;
    // left: 10px;
    // z-index: 999;
    overflow: hidden;

    // @media (max-width: 730px) {
    //   width: 90%;
    //   height: 240px;
    // }
  }
  .flash-collab-video-win {
    height: 180px !important;
    width: 320px !important;
  }
}

.screen-sharing-client {
  height: 100%;
  width: 70%;
  top: 0;
  left: 0;
  z-index: 99;
  bottom: 0;
  right: 0;
  margin: auto;
}

.ai-app-translateAssist {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  padding: 10px;


  .identify-section {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;


    .identify-section-select {
      display: flex;
      border: 1px solid #ACA59D;
      border-radius: 15px;
      box-sizing: border-box;
      width: 100%;
      padding: 10px;
      background-color: #EFF0FA;


      .identify-section-flex {
        flex: 1;

        h4 {
          margin: 0 0 8px;
          font-size: 16px;
          color: #535252;
        }
      }

      .swap-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 50px;


        .swap-button {
          align-self: center;

          cursor: pointer;
          font-size: 16px;
          background: transparent;
          border: none;
        }
      }

    }





  }


  .translate-form {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .ai-translate-section {
      width: 100%;
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      gap: 20px;

      .translate-section-flex {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
      }

      .identify-textarea {
        width: 100%;
        height: 100%;
        white-space: normal;
        border: 1px solid #ccc;
        overflow-wrap: break-word;
        padding-right: 30px;
        position: relative;
        resize: none;
        box-sizing: border-box;
        font-size: 16px;
      }

      .origin-input-box {
        position: relative;

        .clear-button {
          position: absolute;
          top: 10px;
          right: 5%;
          border: none;
          background: transparent;
          cursor: pointer;
          font-size: 16px;
          color: #888;
        }
      }


      .translate-text-section-flex {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
      }

      .translate-textarea {
        width: 100%;
        height: 100%;
        // background-color: #f0f0f0;
        border: 1px solid #ccc;
        white-space: normal;
        overflow-wrap: break-word;
        resize: none;
        box-sizing: border-box;
        font-size: 16px;
      }


    }

    .action {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 20px;

      Button {
        width: 120px;
        height: 40px;
      }
    }

  }

}
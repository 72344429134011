.fileInputLabel {
    display: inline-block;
    padding: 8px 16px;
    background-color: #6965db;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}

.fileInput {
    display: none;
}

.uploadButton {
    display: block;
    margin-top: 16px;
    padding: 8px 16px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.uploadButton:hover {
    background-color: #45a049;
}

.table-search-container{
    padding: 0.65rem 0;

}

.googldrive-file-title{
    display: flex;
    justify-content:space-between;
    align-items: center;
    height: 50px;

   
}

.googldrive-file-title img{
    max-width: 50px;
    max-height: 50px;
}

.white-icon svg{
    fill: white;
      
}

@import "open-color/open-color.scss";
@import "../css/variables.module";
.pagination{
    width: var(--lg-button-size);
    height: var(--lg-button-size);
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    a{
        cursor: pointer;
        text-decoration: none;
    }
    a:hover{
        text-decoration: none;
    }
    a.page-title{
        display: flex;
        svg{
            width: var(--lg-icon-size);
            height: var(--lg-icon-size);
        }
    }
    .pagination-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 200px;
        padding: 10px 10px 10px 20px;
        border-bottom: 1px solid #ccc;
    }
    .pagination-list{
       // width: 110px;
    }

    .add-page{
        border: 0;
        background: none;
        svg{
            width: var(--lg-icon-size);
            height: var(--lg-icon-size);
        }
    }
    .list-item{
        cursor: pointer;
        width: 100%;
        display: flex;
        justify-content: space-between;

        svg{
            width: var(--lg-icon-size);
            height: var(--lg-icon-size);
        }
        a{
            display: flex;
            align-items: center;
            color: #000000;
        }
        a:hover{
            color: #2657B0;
            text-decoration: none;
        }
        &-title{
            display: flex;
            align-items: center;
            gap: 5px;
            width: 100px;
            span{
                display: flex;
                align-items: center;
                width: 24px;
            }
        }
        &-actions{
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }

    
}
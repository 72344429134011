.image-gallery-source-container {
    background-color: #fff;  
    
    min-height: 200px;
    height: 100%;
    border-radius: 4px; 
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex;         /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: row;        
    justify-content: center;
    align-content:start;
    flex-wrap:wrap;
    position: relative;
    gap: 2rem;
    flex-basis:0%;
    .image-gallery-qrcode-background{
        position: absolute;
        width: 100%;      
        height: 100%;  
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .image-gallery-qrcode-container{        
        display: flex;
        background-color: #FFFFFF;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        .title{
            font-size: 16px;
            font-weight: bolder;
            margin-bottom: 20px;
        }
        a{
            position: absolute;
            top:0;
            right: 0;
            cursor: pointer;
        }
    }
    .gallery-image-container{    
        position: relative;
        // margin-top: 1rem;
        // margin-bottom: 1rem;
        height: 7.5rem;   
        padding: 5px;
        border: 1px solid #e9ecef;
        border-radius: 5px;
    }
 
    .gallery-image-container img{
        height: 100%;
        object-fit: contain;
    }
    .gallery-image-container a{
        position:absolute;
        top: 0;
        right: 0;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .image-gallery-empty{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border: 1px dashed #B1BFD0;
        span{
            color:#1F4690;
        }
        p{
            font-size: 0.75rem;
            color: #969DB2;
        }
    }
  }

  .more-action{
    padding: 10px;
   .action-item{
       
        a{
            font-size: 1rem;
            font-weight: normal;
            line-height: 1.5rem;
            color: #000;
            cursor: pointer;
        }
        a:hover{
            text-decoration: none;
        }
   }
}